<template>
  <div class="coinater">
    <div class="fdleft">
      <!-- <div class="fdtitle">
        <div></div>
        <div class="fdtitlee">
          <div class="fdtfir">形式审查</div>
          <div class="fdtsec">{{ form.name }}</div>
        </div>
      </div> -->
      <div class="fdlcont">
        <div class="fdltitle">申报信息汇总表</div>
        <div class="fdltxt">
          <div class="table">
            <el-form ref="form" :model="form" label-width="150px">
              <el-row :gutter="50">
                <el-col :span="12">
                  <div>
                    <div class="gezi name_input">
                      <el-form-item
                        :rules="{
                          required: true,
                          message: '请填写项目名称',
                          trigger: 'blur',
                        }"
                        label="项目名称"
                        prop="name"
                      >
                        {{ form.name }}
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item
                        :rules="{
                          required: true,
                          message: '请选择活动区域',
                          trigger: 'change',
                        }"
                        label="所属地区"
                        prop="city"
                      >
                        {{ form.city }}
                      </el-form-item>
                      <div class="geziright">
                        <el-form-item
                          :rules="{
                            required: true,
                            message: '请选择活动区域',
                            trigger: 'change',
                          }"
                          label="所属区县"
                          prop="county"
                        >
                          {{ form.county }}
                        </el-form-item>
                      </div>
                    </div>
                    <div class="gezi">
                      <el-form-item
                        :rules="{
                          required: true,
                          message: '请填写建筑面积',
                          trigger: 'blur',
                        }"
                        label="建筑面积（m²）"
                        prop="building_area"
                      >
                        {{ form.building_area }}
                      </el-form-item>
                      <div class="geziright">
                        <el-form-item
                          :rules="{
                            required: true,
                            message: '请填写占地面积',
                            trigger: 'blur',
                          }"
                          label="占地面积（m²）"
                          prop="area"
                        >
                          {{ form.area }}
                        </el-form-item>
                      </div>
                    </div>
                    <div class="gezi">
                      <el-form-item
                        :rules="{
                          required: true,
                          message: '请选择测评阶段',
                          trigger: 'change',
                        }"
                        label="测评阶段"
                        prop="stage"
                      >
                        <span v-if="form.stage == 1">建筑能效测评</span>
                        <span v-if="form.stage == 2">建筑能效实测评估</span>
                      </el-form-item>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item
                        :rules="{
                          required: true,
                          message: '请填写测评单位',
                          trigger: 'blur',
                        }"
                        label="测评单位"
                        prop="test_company"
                      >
                        {{ form.test_company }}
                      </el-form-item>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item
                        :rules="{
                          required: true,
                          message: '请填写测评联系人',
                          trigger: 'blur',
                        }"
                        label="测评联系人"
                        prop="test_contact"
                      >
                        {{ form.test_contact }}
                      </el-form-item>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item
                        :rules="{
                          required: true,
                          message: '请填写测评联系人电话',
                          trigger: 'blur',
                        }"
                        label="测评联系人电话"
                        prop="test_tel"
                      >
                        {{ form.test_tel }}
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item
                        :rules="{
                          required: true,
                          message: '请填写测评时间',
                          trigger: 'change',
                        }"
                        label="测评时间"
                        prop="test_time"
                      >
                        {{ form.test_time | formatDate("yyyy-MM-dd") }}
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item
                        :rules="{
                          required: true,
                          message: '外观图',
                          trigger: 'change',
                        }"
                        label="外观图"
                        prop="test_time"
                      >
                        <div v-for="(item, index) in formfiles" :key="index">
                          <div
                            style="
                              height: 250px;
                              width: 250px;
                              border: 1px solid #000;
                            "
                            v-if="item.show"
                            @click="downloadPic(item)"
                          >
                            <img
                              :src="item.url"
                              alt=""
                              style="height: 100%; width: 100%"
                            />
                          </div>
                        </div>
                        <span
                          v-if="haveShowPic"
                          style="color: #409eff; font-size: 12px"
                          >（点击图片直接下载）</span
                        >
                        <span v-else> 外观图未上传</span>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content bg-purple-light">
                    <div class="gezi name_input">
                      <el-form-item label="项目地址" prop="address" required>
                        {{ form.address }}
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item
                        label="建筑类型"
                        prop="building_type"
                        required
                      >
                        <span v-if="form.building_type == 1">公共建筑</span>
                        <span v-if="form.building_type == 2">居住建筑</span>
                      </el-form-item>
                      <div class="geziright">
                        <el-form-item
                          label="建筑功能"
                          prop="building_ability"
                          required
                        >
                          <span v-if="form.building_type == 1">
                            <span v-if="form.building_ability == 1"
                              >办公建筑</span
                            >
                            <span v-if="form.building_ability == 2"
                              >商场建筑</span
                            >
                            <span v-if="form.building_ability == 3"
                              >宾馆饭店建筑</span
                            >
                            <span v-if="form.building_ability == 4"
                              >文化教育建筑</span
                            >
                            <span v-if="form.building_ability == 5"
                              >医疗卫生建筑</span
                            >
                            <span v-if="form.building_ability == 6"
                              >其他建筑</span
                            >
                          </span>
                          <span v-else>
                            <span v-if="form.building_ability == 1">住宅</span>
                            <span v-if="form.building_ability == 2">基地</span>
                          </span>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="gezi">
                      <el-form-item label="层数" prop="floor" required>
                        {{ form.floor }}
                      </el-form-item>
                      <div class="geziright">
                        <el-form-item label="气候区域" prop="weather" required>
                          <span v-if="form.weather == 1">夏热冬冷</span>
                          <span v-if="form.weather == 2">寒冷</span>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="gezi">
                      <el-form-item label="测评星级" prop="stars" required>
                        <span>
                          <i
                            class="el-icon-star-on"
                            v-for="i in form.stars"
                            :key="i"
                          ></i>
                        </span>
                      </el-form-item>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item
                        label="建设单位"
                        prop="build_company"
                        required
                      >
                        {{ form.build_company }}
                      </el-form-item>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item label="项目负责人" prop="manger" required>
                        {{ form.manger }}
                      </el-form-item>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item label="项目联系人" prop="contact" required>
                        {{ form.contact }}
                      </el-form-item>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item
                        label="项目联系人电话"
                        prop="contact_tel"
                        required
                      >
                        {{ form.contact_tel }}
                      </el-form-item>
                    </div>

                    <div class="gezi name_input">
                      <el-form-item label="承诺函">
                        <div
                          v-if="chengnuohanFiles && chengnuohanFiles.length > 0"
                        >
                          <div
                            v-for="file in chengnuohanFiles"
                            :key="file.id"
                            style="
                              display: inline-block;
                              white-space: nowrap;
                              width: 100%;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                          >
                            <span
                              @click="downloadFile(file)"
                              style="color: #409eff; cursor: pointer"
                              >{{ file.file }}</span
                            >
                          </div>
                        </div>
                        <div v-else>
                          <span>承诺函未上传</span>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="fdright">
      <div class="fdtitlee">
        <div class="fdtsec">{{ form.name }}</div>
      </div>
      <div class="fdbacck">
        <el-button @click="goBack" type="primary">返回</el-button>
      </div>
      <div class="fdrcont">
        <div class="fdrtitle">审查历史记录</div>
        <div class="fdrtxt">
          <div v-for="item in shencha1" :key="item.id" class="fdrlist">
            <div class="fdrlcont">
              {{ item.idea }}
            </div>
            <div class="fdrtime">
              {{ item.created_at | formatDate("yyyy-MM-dd") }}
            </div>
          </div>
        </div>
        <div class="fdrtitle">审查意见</div>
        <div class="fdrtxt">
          <el-input
            :disabled="
              !edit ||
              (form.status >= 5 && form.status != 15 && form.status != 16)
            "
            v-model="idea"
            placeholder="请输入内容"
            style="height: 250px; border: 1px solid #dcdfe6"
            type="textarea"
          >
          </el-input>
        </div>
        <div class="fdradio">
          <el-radio-group v-model="pass" :disabled="!edit">
            <el-radio :label="1">达标</el-radio>
            <el-radio :label="2">未达标</el-radio>
            <el-radio :label="3">待定</el-radio>
          </el-radio-group>
        </div>
        <div class="fdrbtn">
          <!-- <el-button type="primary">上一页</el-button> -->
          <el-button
            type="primary"
            :disabled="
              !edit ||
              (form.status >= 5 && form.status != 15 && form.status != 16)
            "
            @click="check"
            >保存</el-button
          >
          <el-button type="primary" @click="next_page">下一页</el-button>
        </div>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="showFile" width="80%">
      <div class="fdltxt">
        <iframe
          :src="fileUrl + '?#toolbar=0'"
          style="width: 100%; height: 76vh"
        ></iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { checkSave, projectTableInfo } from "../../../../api/project";
import axios from "axios";

// let docx = require('docx-preview');

export default {
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      fileUrl: "",
      ifreamShow: true,
      showFile: false,
      idea: "",
      pass: "",
      formtable: "",
      project_id: "",
      form: {},
      shencha2: [],
      formfiles: [],
      showFirst: -1,
      weatherOptions: [
        {
          name: "办公建筑",
          id: 1,
        },
        {
          name: "商场建筑",
          id: 2,
        },
        {
          name: "宾馆饭店建筑",
          id: 3,
        },
        {
          name: "文化教育建筑",
          id: 4,
        },
        {
          name: "医疗卫生建筑",
          id: 5,
        },
        {
          name: "其他建筑",
          id: 6,
        },
      ],
      //居住建筑
      juzhuOptions: [
        {
          name: "住宅",
          id: 1,
        },
        {
          name: "基地",
          id: 2,
        },
      ],

      //冷水热泵机组
      jizu: [{}, {}, {}],

      //单元式空气调节机、风管送风式和屋顶调节机组
      jizu2: [{}, {}],

      jizu3: [{}, {}],
      jizu4: [{}, {}],

      edit: true,
      //1是从形式审查调过来的，2是从技术审查跳过来的
      from: 1,

      shencha1: [],

      haveShowPic: false,

      chengnuohanFiles: [],
    };
  },
  mounted() {
    this.project_id = this.$route.query.project_id;
    if (this.$route.query.from) {
      this.from = this.$route.query.from;
    }
    this.getInfo();
  },
  methods: {
    downloadFile(row) {
      let url = row.url;
      if (url) {
        let file_type = url.split(".");
        this.fileUrl = url;
        if (file_type[file_type.length - 1] == "docx") {
          let a = document.createElement("a");
          a.href = url;
          a.click();
        } else {
          this.showFile = true;
        }
      }
      /*     axios.get(row.url, { responseType: 'blob', params: {}}).then((res) => {
        let fileName = row.file;
        if (!res.data) {
          this.$message.warning(fileName + "下载失败！");
          return
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([res.data]), fileName);
        } else {
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      }).catch(() => {
        this.$message.warning(row.file + "下载失败！");
      });*/
    },

    downloadPic(item) {
      axios
        .get(item.url, { responseType: "blob", params: {} })
        .then((res) => {
          let fileName = item.file;
          if (!res.data) {
            this.$message.warning(item.file + "下载失败！");
            return;
          }
          if (typeof window.navigator.msSaveBlob !== "undefined") {
            window.navigator.msSaveBlob(new Blob([res.data]), fileName);
          } else {
            let url = window.URL.createObjectURL(new Blob([res.data]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
          }
        })
        .catch(() => {
          this.$message.warning(item.file + "下载失败！");
        });
    },

    next_page() {
      // let params = {
      //   idea: this.idea,
      //   pass: this.pass,
      //   project_id: this.project_id,
      //   type: 1,
      //   token: this.$store.state.token,
      // };
      // let that = this;
      // checkSave(params).then((res) => {
      //   console.log(res);
      //   // that.$message.success('保存成功');
      //   that.$router.push({
      //     path: "/formal/totalTable?project_id=" + this.project_id,
      //   });
      // });
      this.$router.push({
        path: "/formal/totalTable?project_id=" + this.project_id,
      });
    },
    goBack() {
      // this.$router.back()
      if (
        this.form.status < 5 ||
        this.form.status == 15 ||
        this.form.status == 16
      ) {
        this.$router.push({
          path: "/formal/stepInformation?id=" + this.project_id,
        });
      } else if (
        this.form.status < 15 ||
        this.form.status == 17 ||
        this.form.status == 18 ||
        this.form.status == 22
      ) {
        this.$router.push({
          path: "/technology/techInformation?id=" + this.project_id,
        });
      }
    },
    check() {
      // if (!this.idea || this.idea=='' ){
      //   this.$message.warning("请填写审核内容")
      //   return false;
      // }
      if (!this.pass || this.pass == "") {
        this.$message.warning("请选择是否达标");
        return false;
      }
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 1,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        this.edit = false;
        that.$message.success("保存成功");

        // that.goBack();
      });
    },
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.form = res.data;
        that.formfiles = that.form.files;
        if (that.formfiles) {
          for (let i = 0; i < that.formfiles.length; i++) {
            if (that.formfiles[i].type == 2) {
              that.formfiles[i].show = true;
              that.haveShowPic = true;
              break;
            }
          }
          that.chengnuohanFiles = [];
          for (let i = 0; i < that.formfiles.length; i++) {
            if (that.formfiles[i].type == 1) {
              that.chengnuohanFiles.push(that.formfiles[i]);
            }
          }
        }
        if (that.form.status > 8) {
          that.edit = false;
        }
        let checks = res.data.checks;
        that.shencha2 = checks
          .map((item) => {
            if (item.type == 7) {
              return item;
            }
          })
          .filter((d) => d);
        that.shencha1 = checks
          .map((item) => {
            if (item.type == 1) {
              return item;
            }
          })
          .filter((d) => d);
        console.log(that.shencha1);
        for (let i = 0; i < checks.length; i++) {
          if (checks[i].type == 1) {
            that.idea = checks[i].idea;
            that.pass = checks[i].pass;
          }
        }
      });
    },
  },
};
</script>
<style scoped>
/deep/.el-form-item__content {
  font-size: 16px;
}
/deep/.el-form-item__label {
  font-size: 16px;
}
/deep/.el-textarea > textarea {
  height: 245px;
}
.table_select .el-select {
  width: 100%;
}

/deep/ .table_select .el-input {
  width: 100%;
}

/deep/ .fdrtxt .el-textarea__inner {
  border: 0;
}

/deep/ .minyong input {
  width: 100px;
  border-bottom: 1px solid #333;
}

/deep/ .minyong .minyong_select .el-input {
  width: 335px;
}

/deep/ .minyong .minyong_select input {
  width: 335px;
}

/deep/ .minyong .el-input__suffix {
  right: -75px;
}
</style>
<style lang="scss" scoped>
.fdrtxt {
  height: 248px;
  overflow-y: scroll;
  border: 1px solid rgb(220, 223, 230);
  .fdrlist {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px dashed rgb(220, 223, 230);
    margin: 10px;
    padding: 3px 0;
    .fdrlcont {
      width: 60%;
    }
  }
}
.fdrtxt::-webkit-scrollbar {
  display: none;
}
.uploadtable {
  margin: 20px 0;
}

input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}

.coinater {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .fdleft {
    width: 74%;

    .fdtitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 96px;

      .fdtitlee {
        display: flex;
        flex-direction: column;

        .fdtfir {
          font-size: 24px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #333333;
          margin-bottom: 8px;
        }

        .fdtsec {
          font-size: 18px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #666666;
        }
      }
    }

    .fdlcont {
      background-color: #ffffff;
      padding: 6px;
      box-shadow: 0px 8px 9px 1px rgba(149, 149, 149, 0.16);
      border-radius: 6px;
      height: 105vh;

      .fdltitle {
        margin-right: 35px;
        height: 60px;
        line-height: 60px;
        padding-left: 23px;
        background: #eeeeee;
        border-radius: 4px;
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
      }

      .fdltxt {
        margin: 35px;
        height: 85vh;
      }
    }
  }

  .fdright {
    width: 25%;
    height: 800px;
    .fdtsec {
      display: flex;
      flex-direction: row-reverse;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #666666;
    }
    .fdbacck {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      height: 96px;
    }

    .fdrcont {
      background-color: #ffffff;
      border-radius: 6px;
      padding: 13px 20px;

      .fdrtitle {
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
        line-height: 23px;
        margin-bottom: 14px;
        margin-top: 10px;
      }

      .fdradio {
        margin: 20px 0;
        text-align: center;
      }

      .fdrbtn {
        text-align: center;
      }
    }
  }
}
</style>
